@font-face {
  font-family: 'Nunito Sans';
  src: url('./assets/font/nunitosans/NunitoSans-Regular.ttf');
}

@font-face {
  font-family: 'Nunito Sans Light';
  src: url('./assets/font/nunitosans/NunitoSans-Light.ttf');
}

@font-face {
  font-family: 'Nunito Sans SemiBold';
  src: url('./assets/font/nunitosans/NunitoSans-SemiBold.ttf');
}

@font-face {
  font-family: 'Nunito Sans ExtraBold';
  src: url('./assets/font/nunitosans/NunitoSans-ExtraBold.ttf');
}

@font-face {
  font-family: 'Cookie';
  src: url('./assets/font/cookie/Cookie-Regular.ttf');
}

@font-face {
  font-family: 'Signika Medium';
  src: url('./assets/font/signika/Signika-Medium.ttf');
}

@font-face {
  font-family: 'Signika Light';
  src: url('./assets/font/signika/Signika-Light.ttf');
}

@font-face {
  font-family: 'Signika Bold';
  src: url('./assets/font/signika/Signika-Bold.ttf');
}

/* @import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;1,300;1,400;1,600;1,700&display=swap'); */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Nunito Sans';
}

:root {
  --font-smallest: 12px;
  --font-small: 14px;
  --font-medium: 18px;
  --font-large: 26px;
  --font-larger: 32px;

  --default-title-color: #32325d;
  --default-dark-gray: #ced4da;
  --default-gray: #8898aa;
  --success-color: #2dce89;
  --primary-color: #525f7f;
  --danger-color: #f5365c;
  --info-color: #11cdef;
  --warning-color: #fb6340;
}

label.required::after {
  content: ' *';
  color: red;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

#global-modal {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  width: 100vw;
  height: 100vh;
  z-index: 4;

  display: flex;
  justify-content: center;
  align-items: center;
}

#global-modal-material {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  width: 100vw;
  height: 100vh;
  z-index: 3;

  display: flex;
  justify-content: center;
  align-items: center;
}

body {
  margin: 0;
  font-family: 'Nunito Sans';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
  ::-webkit-scrollbar {
      width: 8px;
    }
    ::-moz-scrollbar {
      width: 8px;
    }
    :-ms-input-scrollbar {
      width: 8px;
    }

    ::-webkit-scrollbar-thumb {
      background: #cecece;
      border-radius: 10px;
    }
